import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok"
import Seo from "@components/Seo"
import StoryBlokSeo from "@components/StoryBlokSeo"

export default function Home({ data }) {
  let story = useStoryblokState(data.storyblokEntry)

  const components = story.content.home_blocks?.map(blok => (
    <StoryblokComponent blok={blok} key={blok._uid} />
  ))

  return (
    <>
      <StoryBlokSeo story={story} />
      {components}
    </>
  )
}

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
