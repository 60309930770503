/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Seo from "@components/Seo"

function StoryBlokSeo({ story }) {
  const seo = {
    title: story.content.meta_title,
    metaDesc: story.content.meta_description,
    opengraphImageSrc: story.content.og_image?.filename,
    metaRobotsNoindex: "index",
    metaRobotsNofollow: "follow",
  }

  return <Seo {...seo} />
}

export default StoryBlokSeo
